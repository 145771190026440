import { Box, DarkMode, Flex, HStack, IconButton } from "@chakra-ui/react";
import {
  Children,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Icon } from "../Icon";

/**
 * @typedef {object} SliderContextValue
 * @property {import("react").MouseEventHandler<HTMLButtonElement>} handleClickNext
 * @property {import("react").MouseEventHandler<HTMLButtonElement>} handleClickPrev
 */

/** @type {SliderContextValue} */
const DEFAULT_VALUE = {
  handleClickNext: () => {},
  handleClickPrev: () => {},
};

const SliderContext = createContext(DEFAULT_VALUE);

export function useSlider() {
  const value = useContext(SliderContext);

  if (value === DEFAULT_VALUE) {
    throw new Error("useSlider must be used within SliderProvider");
  }

  return value;
}

export const Slider = memo(
  /**
   * @typedef {object} Props
   * @property {any} [itemWidth] css width or chakra responsive width. if not provied, the slider will scroll by the width of the container
   * @property {boolean} [scrollSnap]
   * @property {string} [gradientColor]
   * @property {boolean} [withPadding]
   * @property {boolean} [revealButtonsOnHover]
   * @property {number} [interval]
   * @property {boolean} [withPrevNextButtons]
   * @property {import("@chakra-ui/react").IconButtonProps["size"]} [prevNextButtonSize]
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function Slider({
    itemWidth,
    scrollSnap = true,
    gradientColor = "var(--chakra-colors-gray-100)",
    withPadding = true,
    revealButtonsOnHover = false,
    interval,
    withPrevNextButtons = true,
    children,
    prevNextButtonSize,
  }) {
    const intl = useIntl();

    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);

    const [timerId, setTimerId] = useState(
      /** @type {NodeJS.Timeout | undefined} */ (undefined),
    );

    const refContainer = useRef(/** @type {HTMLDivElement | null} */ (null));
    const refFirstElement = useRef(/** @type {HTMLDivElement | null} */ (null));

    const childrens = Children.toArray(children);

    const mountedRef = useRef(false);

    const handleNext = useCallback(
      /** @param {"auto" | "manual"} mode */
      (mode) => {
        if (refContainer.current && refFirstElement.current) {
          if (mode === "auto") {
            setTimerId(
              setTimeout(() => {
                handleNext("auto");
              }, interval),
            );
          }
          if (itemWidth !== undefined) {
            const scrollLeft = refContainer.current.scrollLeft;

            const hasReachedEnd =
              (childrens.length - 1) * refFirstElement.current.clientWidth;

            if (scrollLeft === hasReachedEnd) {
              refContainer.current.scrollLeft = 0;
            } else {
              refContainer.current.scrollLeft +=
                refFirstElement.current.clientWidth *
                (refContainer.current.clientWidth /
                  refFirstElement.current.clientWidth);
            }
          } else {
            refContainer.current.scrollLeft += refContainer.current.clientWidth;
          }
        }
      },
      [childrens.length, interval, itemWidth],
    );

    const startAutoScroll = useCallback(() => {
      if ((interval ?? 0) > 0) {
        setTimerId(
          setTimeout(() => {
            handleNext("auto");
          }, interval),
        );
      }
    }, [handleNext, interval]);

    const handleClickNext = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLButtonElement>} */
      (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        handleNext("manual");
      },
      [handleNext],
    );

    const handlePrev = useCallback(() => {
      if (refContainer.current && refFirstElement.current) {
        if (itemWidth !== undefined) {
          const scrollLeft = refContainer.current.scrollLeft;

          if (scrollLeft === 0) {
            refContainer.current.scrollLeft +=
              childrens.length * refFirstElement.current.clientWidth;
          } else {
            refContainer.current.scrollLeft -=
              refFirstElement.current.clientWidth *
              (refContainer.current.clientWidth /
                refFirstElement.current.clientWidth);
          }
        } else {
          refContainer.current.scrollLeft -= refContainer.current.clientWidth;
        }
      }
    }, [childrens.length, itemWidth]);

    const handleClickPrev = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLButtonElement>} */
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        handlePrev();
      },
      [handlePrev],
    );

    // Start the slider
    if (mountedRef.current === false) {
      mountedRef.current = true;
      startAutoScroll();
    }

    const style = useMemo(() => {
      /** @type {import("@chakra-ui/react").BoxProps} */
      const value = {
        ...(scrollSnap
          ? {
              scrollSnapStop: "normal",
              scrollSnapAlign: "start",
              scrollMarginLeft: withPadding ? ".5rem" : undefined,
            }
          : {}),
        flexShrink: 0,
        px: withPadding ? ".5rem" : undefined,
        w: itemWidth,
      };
      return value;
    }, [withPadding, itemWidth, scrollSnap]);

    const handleScroll = useCallback(() => {
      setShowLeftButton(refContainer.current?.scrollLeft !== 0);
      setShowRightButton(
        (refContainer.current?.scrollLeft ?? 0) +
          (refContainer.current?.clientWidth ?? 0) <
          (refContainer.current?.scrollWidth ?? 0) - 1,
      );
    }, []);

    useEffect(() => {
      handleScroll();

      const resizeObserver = new ResizeObserver(handleScroll);
      const container = refContainer.current;

      if (container) {
        resizeObserver.observe(container);
      }

      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
        resizeObserver.disconnect();
      };
    }, [handleScroll]);

    const handleMouseEnter = useCallback(() => {
      clearTimeout(timerId);
    }, [timerId]);

    const handleMouseLeave = useCallback(() => {
      startAutoScroll();
    }, [startAutoScroll]);

    const value = useMemo(() => {
      return { handleClickNext, handleClickPrev };
    }, [handleClickNext, handleClickPrev]);

    return (
      <SliderContext.Provider value={value}>
        <Box
          position="relative"
          role="group"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <HStack
            ref={refContainer}
            spacing="0"
            overflow="auto"
            px={withPadding ? ".5rem" : undefined}
            scrollSnapType="x mandatory"
            scrollBehavior="smooth"
            alignItems="stretch"
            onScroll={handleScroll}
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}>
            {childrens.map((child, index) => (
              <Flex
                w="full"
                alignItems="stretch"
                ref={index === 0 ? refFirstElement : undefined}
                key={index}
                sx={{
                  "& > *": {
                    flexGrow: 1,
                  },
                }}
                {...style}>
                {child}
              </Flex>
            ))}
          </HStack>

          <Box
            display={{ base: "none", lg: "block" }}
            position="absolute"
            top="0"
            left="0"
            bottom="0"
            w="1rem"
            background={`linear-gradient(to left, rgba(255, 255, 255, 0), ${gradientColor})`}
          />

          <Box
            display={{ base: "none", lg: "block" }}
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            w="1rem"
            background={`linear-gradient(to right, rgba(255, 255, 255, 0), ${gradientColor})`}
          />

          {withPrevNextButtons && (
            <DarkMode>
              <Box
                display={{ base: "none", lg: "block" }}
                opacity={showLeftButton ? (revealButtonsOnHover ? 0 : 1) : 0}
                pointerEvents={showLeftButton ? "auto" : "none"}
                position="absolute"
                left="16px"
                top="50%"
                transform={`translateY(-50%) scale(${showLeftButton ? 1 : 0})`}
                backgroundColor="rgba(0, 0, 0, 0.2)"
                backdropFilter="blur(4px)"
                borderRadius="full"
                _groupHover={revealButtonsOnHover ? { opacity: 1 } : undefined}>
                <IconButton
                  borderRadius="full"
                  size={prevNextButtonSize}
                  icon={<Icon icon="ms_chevron_left" size="24px" />}
                  variant="ghost"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Précédent",
                  })}
                  onClick={handleClickPrev}
                />
              </Box>

              <Box
                display={{ base: "none", lg: "block" }}
                opacity={showRightButton ? (revealButtonsOnHover ? 0 : 1) : 0}
                pointerEvents={showRightButton ? "auto" : "none"}
                position="absolute"
                right="16px"
                top="50%"
                transform={`translateY(-50%) scale(${showRightButton ? 1 : 0})`}
                backgroundColor="rgba(0, 0, 0, 0.2)"
                backdropFilter="blur(4px)"
                borderRadius="full"
                _groupHover={revealButtonsOnHover ? { opacity: 1 } : undefined}>
                <IconButton
                  borderRadius="full"
                  size={prevNextButtonSize}
                  icon={<Icon icon="ms_chevron_right" size="24px" />}
                  variant="ghost"
                  aria-label={intl.formatMessage({ defaultMessage: "Suivant" })}
                  onClick={handleClickNext}
                />
              </Box>
            </DarkMode>
          )}
        </Box>
      </SliderContext.Provider>
    );
  },
);

import { Box, Skeleton, Stack, Text } from "@chakra-ui/react";
import { getPostSlug } from "@raiden/library-ui/helpers/posts/getSlug";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { CampaignsShowcaseDestination } from "../../components/Campaigns/ShowcaseDestination";
import { CampaignsThematicShowcase } from "../../components/Campaigns/ShowcaseThematic";
import { CenteredContent } from "../../components/CenteredContent";
import { Href } from "../../components/Href";
import { PageCard } from "../../components/Posts/PageCard";
import { PostsSlider } from "../../components/Posts/Slider";
import { SectionPublic } from "../../components/SectionPublic";
import { Slider } from "@raiden/library-ui/components/Slider";
import { Z_INDEX } from "../../constants/zIndex";
import { SearchWidget } from "../Search/Widget";
import { HotelsTypesSlider } from "../../components/SearchHotels/HotelsTypesSlider";
import { Regions } from "./Regions";
import { Reinssurance } from "./Reinssurance";
import { SlideshowItem } from "./SlideshowItem";
import { SpecialOffers } from "./SpecialOffers";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { CampaignSeoBlock } from "../../components/Campaigns/SeoBlock";

/**
 * @typedef {object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Configuration").Configuration>>} swrResponseConfigurationHomepage
 * @property {number} [slideshowItemIndex]
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>>} swrResponsePostsTypeArticle
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>>} swrResponsePostsTypePage
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>>} swrResponseCampaignsTypeThematic
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>} [campaignsTypeDestinationResponse]
 * @property {number} [initialCategoryId]
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>>} swrResponseCampaignsCategorySpecialOffers
 * @property {import("@raiden/library-ui/types/CampaignCategory").CampaignCategory[]} [campaignCategoriesTypeDestination]
 */
/**
 * @param {Props} props
 */
export function HomePublic({
  swrResponseConfigurationHomepage,
  slideshowItemIndex: _slideshowItemIndex,
  swrResponsePostsTypeArticle,
  swrResponsePostsTypePage,
  swrResponseCampaignsTypeThematic,
  campaignsTypeDestinationResponse,
  swrResponseCampaignsCategorySpecialOffers,
  initialCategoryId,
  campaignCategoriesTypeDestination,
}) {
  const intl = useIntl();

  const { locale, defaultLocale } = useLocale();

  const { configuration } = useConfiguration();

  const slideshow =
    swrResponseConfigurationHomepage.data?.data.homepage?.slideshow;

  const slideshowItems = slideshow?.items ?? [];

  const withSlideshow =
    swrResponseConfigurationHomepage.isValidating || slideshowItems.length > 0;

  return (
    <Box>
      <Box position="relative">
        {withSlideshow && (
          <Skeleton isLoaded={slideshowItems.length > 0} minH="100px">
            <CenteredContent maxWidth="100%">
              <Slider
                itemWidth="100%"
                withPadding={false}
                withPrevNextButtons={false}
                interval={7500}
                gradientColor="transparent">
                {slideshowItems.map((slideshowItem) => (
                  <SlideshowItem
                    slideshowItem={slideshowItem}
                    countSlides={slideshowItems.length}
                    key={slideshowItem.id}
                  />
                ))}
              </Slider>
            </CenteredContent>
          </Skeleton>
        )}

        <Box
          zIndex={Z_INDEX.public_homeSearchWidget}
          position={withSlideshow ? { lg: "absolute" } : undefined}
          bottom={withSlideshow ? { lg: "0" } : undefined}
          left={withSlideshow ? { lg: "0" } : undefined}
          right={withSlideshow ? { lg: "0" } : undefined}
          transform={withSlideshow ? { lg: "translateY(50%)" } : undefined}
          px="1rem"
          mt={withSlideshow ? { base: "1.5rem", lg: "0" } : "1.5rem"}>
          <CenteredContent maxWidth="980px">
            <SearchWidget
              withFilters={false}
              withSort={false}
              withDetailledSubmitButton={true}
            />
          </CenteredContent>
        </Box>
      </Box>

      <Stack spacing="2rem" py="6.25rem">
        <Box px="1rem">
          <CenteredContent>
            <Text as="h1" variant="h1">
              {intl.formatMessage({
                defaultMessage:
                  "Clévacances : location de vacances, gîte et chambre d’hôtes en France",
              })}
            </Text>
          </CenteredContent>
        </Box>

        <Stack spacing="3rem">
          {swrResponseCampaignsCategorySpecialOffers.data?.data &&
            swrResponseCampaignsCategorySpecialOffers.data.data.length > 0 && (
              <SectionPublic
                title={intl.formatMessage({
                  defaultMessage: "Offres spéciales",
                })}
                description={intl.formatMessage({
                  defaultMessage:
                    "Les coups de coeur et bons plans Clévacances",
                })}>
                <SpecialOffers
                  campaigns={
                    swrResponseCampaignsCategorySpecialOffers.data.data
                  }
                />
              </SectionPublic>
            )}

          <DataHandler
            swrResponse={swrResponseCampaignsTypeThematic}
            renderNoResultSplash={false}>
            {({ data: campaignsTypeThematic }) => (
              <SectionPublic
                title={intl.formatMessage({
                  defaultMessage: "Idées coups de coeur",
                })}
                description={intl.formatMessage({
                  defaultMessage: "Trouvez l'inspiration pour votre séjour",
                })}
                dataGtmId="homepage-thematic-campaigns-section">
                <CampaignsThematicShowcase campaigns={campaignsTypeThematic} />
              </SectionPublic>
            )}
          </DataHandler>

          {!swrResponsePostsTypePage.isValidating && (
            <SectionPublic
              title={intl.formatMessage({
                defaultMessage: "Clévacances à votre service",
              })}
              description={intl.formatMessage({
                defaultMessage:
                  "Nos engagements pour vous garantir un séjour inoubliable",
              })}>
              <CenteredContent
                maxWidth={({ defaultMaxWidth }) =>
                  `calc(${defaultMaxWidth} + 2rem)`
                }>
                <Slider
                  itemWidth={{
                    base: "calc(100% - (2rem / 1))",
                    sm: "calc(50% - (2rem / 2))",
                    md: "33.33%",
                  }}>
                  {swrResponsePostsTypePage.data?.data.map((post) => (
                    <Href
                      key={post.id}
                      href={generateFrontPath({
                        id: "@front.page.view",
                        parameters: {
                          postSlug: getPostSlug({
                            post,
                            locale,
                            defaultLocale,
                          }),
                        },
                      })}>
                      <PageCard post={post} />
                    </Href>
                  ))}
                </Slider>
              </CenteredContent>
            </SectionPublic>
          )}

          {Array.isArray(campaignCategoriesTypeDestination) &&
            campaignCategoriesTypeDestination.length > 0 && (
              <SectionPublic
                title={intl.formatMessage({
                  defaultMessage: "Nos destinations incontournables",
                })}
                description={intl.formatMessage({
                  defaultMessage:
                    "Découvrez les destinations idéales selon vos envies",
                })}
                dataGtmId="homepage-showcase-campaign-section">
                <CampaignsShowcaseDestination
                  initialCategoryId={initialCategoryId}
                  isFederal={true}
                  campaignCategories={campaignCategoriesTypeDestination}
                  campaignsTypeDestinationResponse={
                    campaignsTypeDestinationResponse
                  }
                />
              </SectionPublic>
            )}

          <SectionPublic
            title={intl.formatMessage({
              defaultMessage: "Des hébergements pour toutes vos envies",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Des cabanes dans les arbres aux gîtes premium, nous avons tout ce qu'il vous faut",
            })}>
            <HotelsTypesSlider hotelTypesCount={configuration.hotels_types} />
          </SectionPublic>

          <Reinssurance />

          {(swrResponsePostsTypeArticle.data?.data.length ?? 0) > 0 && (
            <SectionPublic
              title={intl.formatMessage({
                defaultMessage: "Les actualités Clévacances",
              })}
              description={intl.formatMessage({
                defaultMessage:
                  "Découvrez nos guides et conseils pour organiser le séjour parfait",
              })}>
              <PostsSlider
                swrResponsePostsTypeArticle={swrResponsePostsTypeArticle}
              />
            </SectionPublic>
          )}

          <Regions />

          <Box px="1rem">
            <CenteredContent>
              <CampaignSeoBlock
                configurationFooterResponse={swrResponseConfigurationHomepage}
              />
            </CenteredContent>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
